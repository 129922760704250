import request from "@/utils/request";
import orderApi from '@/const/apiUrl/order'
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";

//订单列表
export function orderList(searchData) {
    return request({
        url: orderApi.orderList,
        method: method.GET,
        data: searchData,
    })
}

//订单列表
export function orderSubList(searchData) {
    return request({
        url: '/order/subList',
        method: method.GET,
        data: searchData,
    })
}

//订单状态
export function orderStatus(status) {
    return request({
        url: orderApi.orderStatus,
        method: method.GET,
        data: status
    })
}
//订单来源
export function orderSource(source) {
    return request({
        url: orderApi.orderSource,
        method: method.GET,
        data: source
    })
}
//订单支付方式
export function orderPayment(payment) {
    return request({
        url: orderApi.orderPayment,
        method: method.GET,
        data: payment
    })
}
//根据总订单ID获取详情
export function orderDetail(detail) {
    return request({
        url: orderApi.orderDetail,
        method: method.GET,
        data: detail
    })
}
//根据总订单ID查看商品简述
export function orderFloat(float) {
    return request({
        url: orderApi.orderFloat,
        method: method.GET,
        data: float
    })
}
// 取消订单原因
export function orderCancel(cancel) {
    return request({
        url: orderApi.orderCancel,
        method: method.POST,
        data: cancel,
        headers: {
          'Content-Type': contentType.formURL
        }
    })
}

//  确认订单
export function orderDelivery(params) {
    return request({
        url: orderApi.orderDelivery,
        method: method.POST,
        data: params
    })
}
//
//  售后退款列表
export function orderRefundList(params) {
    return request({
        url: orderApi.orderRefundList,
        method: method.POST,
        data: params
    })
}
//  售后换货列表
export function orderReturnList(params) {
    return request({
        url: orderApi.orderReturnList,
        method: method.POST,
        data: params
    })
}
//  售后列表数量
export function orderAfterNum() {
    return request({
        url: orderApi.orderAfterNum,
        method: method.GET,
    })
}
//  售后订单详情
export function orderAfterDetail(params) {
    return request({
        url: `${orderApi.orderAfterDetail}/${params}`,
        method: method.GET,
    })
}
//  售后订单驳回
export function orderAfterReject(params) {
    return request({
        url: orderApi.orderAfterReject,
        method: method.POST,
        data: params,
        headers: {
          'Content-Type': contentType.formURL
        }
    })
}
//  换货申请通过
export function oredrAfterReissue(params) {
    return request({
        url: `${orderApi.oredrAfterReissue}`,
        method: method.POST,
        data: params,
        headers: {
          'Content-Type': contentType.formURL
        }
    })
}

//  退款申请通过
export function oredrAfterRefund(params) {
    return request({
        url: orderApi.oredrAfterRefund,
        method: method.POST,
        data: params,
        headers: {
          'Content-Type': contentType.formURL
        }
    })
}

//  换取换取总订单id
export function oredrGetCombineId(params) {
    return request({
        url: `${orderApi.oredrGetCombineId}`,
        method: method.GET,
        data: params
    })
}

//子订单部分退款
export function orderPartRefund(params) {
    return request({
        url: orderApi.orderPartRefund,
        method: method.POST,
        data: params,
    })
}
//平台支付宝转账到个人接口
export function transferFromAlipay(params) {
    return request({
        url: orderApi.transferFromAlipay,
        method: method.POST,
        data: params,
    })
}

//子订单分账转至自营店
export function transferStoreCashOut(params) {
    return request({
        url: orderApi.transferStoreCashOut,
        method: method.GET,
        data: params,
    })
}

//逻辑删除总订单
export function deleteOrder(params){
	return request({
	    url: orderApi.deleteOrder,
	    method: method.GET,
		data:params,
	})
}

//手动结算
export function doOrderSettle(id){
	return request({
	    url: "/order/doOrderSettle?id="+id,
	    method: method.GET,
	})
}
